
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


















































































































































































































































































.subtitle {
  font-size: 1.8rem;
}

.icon-elec {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.icon-gaz {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.coordinator-firstname {
  text-transform: capitalize;
}

.steps {
  background: #f3f3f3;
  counter-reset: step;
  width: 120%;
  margin: 30px -6% 50px -6%;
  padding: 0 6%;
}

.steps--title {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding: 1.5em 0 0.5em 0;
}

.steps--items {
  padding: 24px 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.steps--items:before {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  top: 20px;
  left: 0;
  background: white;
}

.steps--items.checked:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: center url('../assets/images/checkTimeLine.svg') no-repeat;
  background-size: contain;
  position: absolute;
  top: 10px;
  right: -10px;
}

.steps--item {
  position: relative;
  padding: 24px 0;
  flex: 1 1 0;
  color: grey;
}

.steps--item.active:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: white;
  border: 3px solid $c-main-primary;
  border-radius: 100px;
  position: absolute;
  top: -13px;
  left: 0;
}

.steps--item.checked:before {
  content: '';
  display: block;
  width: 105%;
  height: 4px;
  position: absolute;
  top: -4px;
  left: 0;
  background: #5dae99;
  /*background: hsla(104, 86%, 38%, 1);*/
}

.steps--item.checked:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: center url('../assets/images/checkTimeLine.svg') no-repeat;
  background-size: contain;
  position: absolute;
  top: -14px;
}

.steps--item__title {
  color: #a0a0a0;
  font-weight: 400;
  margin: 0;
  font-size: 1.5rem;
}

.steps--item__info {
  margin: 0;
  font-size: 1.4rem;
  color: hsla(0, 0%, 63%, 1);
}

.steps--item__title:before {
  counter-increment: step;
  content: counter(step) '. ';
}

.steps--item.checked .steps--item__title {
  /* color: hsla(104, 86%, 38%, 1);*/
  color: #5dae99;
}

.steps--item.active .steps--item__title {
  /*color: hsla(355, 100%, 50%, 1);*/
  color: $c-main-primary;
}

.steps--item.active .steps--item__info {
  color: $c-gray-darkest;
}

@media screen and (max-width: 960px) {
  .steps--item {
    visibility: hidden;
    width: 0;
    height: 0;
    flex: none;
    padding: 24px 3px;
  }

  .steps--item.active,
  .steps--item.active + .steps--item {
    visibility: visible;
    width: 48%;
    height: auto;
  }
}
